import React from "react";
import {
  getButtonVariants,
  getButtonComponent,
} from "../components/PlaygroundComponents/ButtonPlayground";
import {
  getActionIconVariants,
  getActionIconComponent,
} from "../components/PlaygroundComponents/ActionIconPlayground";
import {
  getCardVariants,
  getCardComponent,
} from "./PlaygroundComponents/CardPlayground";
import {
  getCheckboxVariants,
  getCheckboxComponent,
} from "./PlaygroundComponents/CheckboxPlayground";
import {
  getChipVariants,
  getChipComponent,
} from "./PlaygroundComponents/ChipPlayground";
import {
  getInputVariants,
  getInputComponent,
} from "./PlaygroundComponents/InputPlayground";
import {
  getLinkVariants,
  getLinkComponent,
} from "./PlaygroundComponents/LinkPlayground";
import {
  getListVariants,
  getListComponent,
} from "./PlaygroundComponents/ListPlayground";
import {
  getRadioButtonVariants,
  getRadioButtonComponent,
} from "./PlaygroundComponents/RadioButtonPlayground";
import {
  getSelectVariants,
  getSelectComponent,
} from "./PlaygroundComponents/SelectPlayground";
import {
  getSwitchVariants,
  getSwitchComponent,
} from "./PlaygroundComponents/SwitchPlayground";

export default class InteractivePlayground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variants: [],
    };
  }

  handleChange = (e) => {
    var newVariants = this.state.variants;
    newVariants.push([e.target.id.toLowerCase(), e.target.value.toLowerCase()]);

    this.setState({
      variants: newVariants,
    });
  };

  getControl(variant) {
    if (variant[1].includes("Falsed")) {
      return (
        <gds-switch
          size="compact"
          mode="light"
          label={variant[0]}
          helpertext=""
          onClick={this.handleChange}
          id={variant[0]}
        ></gds-switch>
      );
    } else if (variant[1].length === 2) {
      return (
        <label className="select">
          {variant[0]}

          <gds-radio-group
            direction="horizontal"
            id={variant[0]}
            onClick={this.handleChange}
          >
            {variant[1].map((variantOption) => (
              <gds-radio
                helpText=""
                id={variant[0]}
                name={variant[0]}
                value={variantOption}
                label={variantOption}
                size="compact"
                mode="light"
                checked={
                  variantOption === variant[2]
                    ? "checked"
                    : variant[2] === undefined &&
                      variantOption === variant[1][0]
                    ? "checked"
                    : null
                }
              ></gds-radio>
            ))}
          </gds-radio-group>
        </label>
      );
    } else {
      return (
        <label className="select">
          {variant[0]}
          <select
            onChange={this.handleChange}
            name={variant[0]}
            id={variant[0]}
          >
            {variant[1].map((variantOption) => (
              <option
                value={variantOption}
                selected={variantOption === variant[2] ? "selected" : null}
              >
                {variantOption}
              </option>
            ))}
          </select>
        </label>
      );
    }
  }

  render() {
    var variants;
    variants = getComponentVariants(this.props.componentName);
    if (variants === null)
      return <gds-chip color="orange">In Progress</gds-chip>;
    return (
      <React.Fragment>
        <div className="interactive-playground row g-0">
          <div className="left col-sm-12 col-md-5">
            {variants.map((variant) => this.getControl(variant))}
          </div>
          <div className="right col">
            {getComponent(this.props.componentName, this.state.variants)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function getComponent(name, variants) {
  if (name === "Button") {
    return getButtonComponent(variants);
  } else if (name === "Action Icon") {
    return getActionIconComponent(variants);
  } else if (name === "Card") {
    return getCardComponent(variants);
  } else if (name === "Checkbox") {
    return getCheckboxComponent(variants);
  } else if (name === "Chip") {
    return getChipComponent(variants);
  } else if (name === "Input") {
    return getInputComponent(variants);
  } else if (name === "Link") {
    return getLinkComponent(variants);
  } else if (name === "List") {
    return getListComponent(variants);
  } else if (name === "Radio") {
    return getRadioButtonComponent(variants);
  } else if (name === "Selectss") {
    return getSelectComponent(variants);
  } else if (name === "Switch") {
    return getSwitchComponent(variants);
  } else return null;
}

function getComponentVariants(name) {
  if (name === "Button") {
    return getButtonVariants();
  } else if (name === "Action Icon") {
    return getActionIconVariants();
  } else if (name === "Card") {
    return getCardVariants();
  } else if (name === "Checkbox") {
    return getCheckboxVariants();
  } else if (name === "Chip") {
    return getChipVariants();
  } else if (name === "Input") {
    return getInputVariants();
  } else if (name === "Link") {
    return getLinkVariants();
  } else if (name === "List") {
    return getListVariants();
  } else if (name === "Radio") {
    return getRadioButtonVariants();
  } else if (name === "Selectss") {
    return getSelectVariants();
  } else if (name === "Switch") {
    return getSwitchVariants();
  } else return null;
}
