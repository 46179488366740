import React from "react";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Regular", "Compact"]];
let helpText = ["Help Text", ["False", "True"]];
let disabled = ["Disabled", ["False", "True"]];
let error = ["Error", ["False", "True"]];

export const getSwitchVariants = function () {
  return [mode, size, helpText, disabled];
};

export const getSwitchComponent = function (variants) {
  let mode = "light";
  let size = "regular";
  let helpText = "false";
  let disabled = null;
  // let error = null;

  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "help text") {
      helpText = variant[1];
    }
    if (variant[0] === "disabled") {
      disabled = variant[1];
    }
    if (variant[0] === "error") {
      error = variant[1];
    }
  }

  var helpTextString = "";
  if (helpText === "true") helpTextString = "Help text";
  if (error === "false") error = null;
  if (disabled === "false") disabled = null;

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-switch
        mode={mode}
        size={size}
        disabled={disabled}
        label="Label"
        error={error}
        helpText={helpTextString}
        checked=""
      ></gds-switch>
    </div>
  );
};
