import React from "react";
// import "@nielsen-media/gds-link/lib/src";

let mode = ["Mode", ["Light", "Dark"]];
let size = [
  "Size",
  [
    "size-0",
    "size-100",
    "size-200",
    "size-300",
    "size-400",
    "size-500",
    "size-600",
    "size-700",
    "size-800",
    "size-900",
    "size-1000",
  ],
  "size-500",
];
let color = ["Color", ["Primary", "Secondary"]];
// let underlined = ["Underlined", ["False", "True"]];
let weight = ["Weight", ["Bold", "Regular"]];

export const getLinkVariants = function () {
  return [mode, size, color, weight];
};

export const getLinkComponent = function (variants) {
  let mode = "light";
  let size = "size-500";
  let color = "primary";
  let underlined = "false";
  let weight = "bold";
  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "color") {
      color = variant[1];
    }
    if (variant[0] === "underlined") {
      underlined = variant[1];
    }
    if (variant[0] === "weight") {
      weight = variant[1];
    }
  }

  size = size.substring(5);

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-link
        mode={mode}
        size={size}
        color={color}
        weight={weight}
        underlined={underlined}
      >
        Label
      </gds-link>
    </div>
  );
};
