import React from "react";
let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Regular", "Compact"]];

export const getCardVariants = function () {
  return [mode, size];
};

export const getCardComponent = function (variants) {
  let mode = "light";
  let size = "regular";

  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
  }

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-card mode={mode} size={size}>
        <div
          className={
            size === "regular"
              ? mode === "light"
                ? "gds-size-700-semibold text-black"
                : "gds-size-700-semibold text-white"
              : mode === "light"
              ? "gds-size-500-semibold text-black"
              : "gds-size-500-semibold text-white"
          }
        >
          Card Title
        </div>
      </gds-card>
    </div>
  );
};
