import React from "react";
// import "@nielsen-media/gds-action-icon/lib/src";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Jumbo", "Regular", "Compact", "Tiny"]];
let icon = ["Icon", ["close", "delete", "caret-down", "caret-up"]];
let disabled = ["Disabled", ["False", "True"]];

export const getActionIconVariants = function () {
  return [mode, size, icon, disabled];
};

export const getActionIconComponent = function (variants) {
  let mode = "light";
  let size = "jumbo";
  let icon = "close";
  let disabled = null;
  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "icon") {
      icon = variant[1];
    }
    if (variant[0] === "disabled") {
      disabled = variant[1];
    }
  }

  if (disabled === "false") disabled = null;

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-action-icon mode={mode} size={size} icon={icon} disabled={disabled}>
        Label
      </gds-action-icon>
    </div>
  );
};
