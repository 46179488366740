import React from "react";
// import "@nielsen-media/gds-button/lib/src";
// import "@nielsen-media/gds-action-icon/lib/src";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Jumbo", "Regular", "Compact", "Tiny"]];
let color = ["Color", ["Primary", "Secondary", "Tertiary", "Danger"]];
let background = ["Background", ["Solid", "Outlined", "Transparent"]];
let disabled = ["Disabled", ["False", "True"]];
let roundedCorners = ["Rounded Corners", ["All", "Left", "Right", "None"]];
let icon = ["Icon", ["None", "Left", "Right"]];

export const getButtonVariants = function () {
  return [mode, size, color, background, disabled, roundedCorners, icon];
};

export const getButtonComponent = function (variants) {
  let mode = "light";
  let size = "jumbo";
  let color = "primary";
  let background = "solid";
  let disabled = null;
  let roundedCorners = "all";
  let icon = "";
  let iconType = "";
  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "color") {
      color = variant[1];
    }
    if (variant[0] === "background") {
      background = variant[1];
    }
    if (variant[0] === "disabled") {
      disabled = variant[1];
    }
    if (variant[0] === "rounded corners") {
      roundedCorners = variant[1];
    }
    if (variant[0] === "icon") {
      icon = variant[1];
    }
  }
  if (icon === "left") {
    iconType = "add-circle";
  }
  if (icon === "right") {
    iconType = "arrow-down";
  }

  if (disabled === "false") disabled = null;

  return (
    <React.Fragment>
      {color === "tertiary" &&
        (background === "outlined" || background === "transparent") && (
          <div id="component-message">
            There are no outlined or transparent tertiary buttons
          </div>
        )}

      <div
        id="component-wrapper"
        className={mode === "dark" ? "toggle-dark" : null}
      >
        <gds-button
          mode={mode}
          size={size}
          color={color}
          background={background}
          disabled={disabled}
          rounded={roundedCorners}
          icon-position={icon}
          icon={iconType}
        >
          Label
        </gds-button>
      </div>
    </React.Fragment>
  );
};
