import React from "react";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Regular", "Compact"]];
let placeholder = ["Placeholder", ["False", "True"]];
let error = ["Error", ["False", "True"]];
let disabled = ["Disabled", ["False", "True"]];
// let label = ["Label", ["False", "True"], "True"];
let helpText = ["Help Text", ["False", "True"]];
let required = ["Required", ["False", "True"]];

export const getInputVariants = function () {
  return [mode, size, placeholder, error, disabled, helpText, required];
};

export const getInputComponent = function (variants) {
  let mode = "light";
  let size = "regular";
  let placeholder = "";
  let error = null;
  let disabled = null;
  let label = "true";
  let helpText = "false";
  let required = "false";
  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "placeholder") {
      placeholder = variant[1];
    }
    if (variant[0] === "error") {
      error = variant[1];
    }
    if (variant[0] === "disabled") {
      disabled = variant[1];
    }
    // if (variant[0] === "label") {
    //   label = variant[1];
    // }
    if (variant[0] === "help text") {
      helpText = variant[1];
    }
    if (variant[0] === "required") {
      required = variant[1];
    }
  }

  if (placeholder === "true") {
    placeholder = "Placeholder";
  } else {
    placeholder = "";
  }

  if (error === "false") error = null;
  if (disabled === "false") disabled = null;
  if (helpText === "true") helpText = "Help Text";
  else helpText = "";
  if (required === "false") required = null;
  else required = "";

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      {label === "false" ? (
        <gds-input
          mode={mode}
          size={size}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
        ></gds-input>
      ) : (
        <gds-label
          label="Label"
          size={size}
          mode={mode}
          helpertext={helpText}
          disabled={disabled}
          error={error}
          required={required}
        >
          <gds-input
            mode={mode}
            size={size}
            placeholder={placeholder}
            error={error}
            disabled={disabled}
          >
            <gds-display-icon
              icon="calendar"
              outlined="false"
              mode="light"
            ></gds-display-icon>
          </gds-input>
        </gds-label>
      )}
    </div>
  );
};
