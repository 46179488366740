import React from "react";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Regular", "Compact", "Tiny"]];
let color = [
  "Color",
  [
    "Gray",
    "Blurple",
    "Aqua",
    "Eggplant",
    "Blue",
    "Green",
    "Red",
    "Yellow",
    "None",
  ],
];
let leftIcon = ["Display Icon", ["False", "True"]];
let rightIcon = ["Action Icon", ["False", "True"]];

export const getChipVariants = function () {
  return [mode, size, color, leftIcon, rightIcon];
};

export const getChipComponent = function (variants) {
  let mode = "light";
  let size = "regular";
  let color = "gray";
  let leftIcon = "";
  let rightIcon = "";
  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "color") {
      color = variant[1];
    }
    if (variant[0] === "display icon") {
      leftIcon = variant[1];
    }
    if (variant[0] === "action icon") {
      rightIcon = variant[1];
    }
  }

  if (leftIcon === "true") leftIcon = "hiking";
  else leftIcon = "none";
  if (rightIcon === "true") rightIcon = "close";
  else rightIcon = "none";

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-chip
        mode={mode}
        size={size}
        color={color}
        lefticon={leftIcon}
        righticon={rightIcon}
      >
        Label
      </gds-chip>
    </div>
  );
};
