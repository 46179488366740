import React from "react";
// import "@nielsen-media/gds-list-item/lib/src";
// import "@nielsen-media/gds-list-group/lib/src";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Jumbo", "Regular", "Compact", "Tiny"], "Regular"];
let divider = ["Divider", ["False", "True"], "False"];
let icon = ["Icon", ["False", "True"], "False"];
let direction = ["Direction", ["Vertical", "Horizontal", "Horizontal Fixed"]];
let itemCount = ["Item Count", ["1", "2", "3", "4"]];

export const getListVariants = function () {
  return [mode, size, divider, icon, direction, itemCount];
};

export const getListComponent = function (variants) {
  let mode = "light";
  let size = "regular";
  let divider = "false";
  let icon = "false";
  let direction = "vertical";
  let itemCount = "1";

  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "divider") {
      divider = variant[1];
    }
    if (variant[0] === "icon") {
      icon = variant[1];
    }
    if (variant[0] === "direction") {
      direction = variant[1];
    }
    if (variant[0] === "item count") {
      itemCount = variant[1];
    }
  }

  let iconType = "";
  let iconSize = "16";

  if (direction === "horizontal fixed") direction = "horizontal-fixed";
  if (icon === "true") {
    iconType = "hiking";
    if (direction === "vertical") {
      if (size === "jumbo") {
        iconSize = "48";
      } else if (size === "regular") {
        iconSize = "40";
      } else if (size === "compact") {
        iconSize = "32";
      } else if (size === "tiny") {
        iconSize = "24";
      }
    } else {
      if (size === "jumbo") {
        iconSize = "24";
      } else if (size === "regular") {
        iconSize = "20";
      } else if (size === "compact") {
        iconSize = "16";
      } else if (size === "tiny") {
        iconSize = "16";
      }
    }
  }

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-list-group size={size} mode={mode}>
        {getListItems(
          itemCount,
          size,
          mode,
          divider,
          direction,
          iconType,
          iconSize
        )}
      </gds-list-group>
    </div>
  );
};

function getListItems(
  count,
  size,
  mode,
  divider,
  direction,
  iconType,
  iconSize
) {
  if (count === "1") {
    return (
      <gds-list-item
        size={size}
        mode={mode}
        divider={divider}
        direction={direction}
      >
        {iconType !== "" && (
          <gds-display-icon
            slot="icon"
            icon={iconType}
            foreground-level={mode === "light" ? "900" : "100"}
            size={iconSize}
          ></gds-display-icon>
        )}
      </gds-list-item>
    );
  }
  if (count === "2") {
    return (
      <React.Fragment>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
      </React.Fragment>
    );
  }
  if (count === "3") {
    return (
      <React.Fragment>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
      </React.Fragment>
    );
  }
  if (count === "4") {
    return (
      <React.Fragment>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
        <gds-list-item
          size={size}
          mode={mode}
          divider={divider}
          direction={direction}
        >
          {iconType !== "" && (
            <gds-display-icon
              slot="icon"
              icon={iconType}
              foreground-level={mode === "light" ? "900" : "100"}
              size={iconSize}
            ></gds-display-icon>
          )}
        </gds-list-item>
      </React.Fragment>
    );
  }
}
