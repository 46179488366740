import React from "react";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Regular", "Compact"]];
let error = ["Error", ["False", "True"]];
let disabled = ["Disabled", ["False", "True"]];
let helpText = ["Help Text", ["False", "True"]];
let required = ["Required", ["False", "True"]];

export const getSelectVariants = function () {
  return [mode, size, error, disabled, helpText, required];
};

export const getSelectComponent = function (variants) {
  let mode = "light";
  let size = "regular";
  let error = null;
  let disabled = null;
  let helpText = "false";
  let required = "false";
  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "error") {
      error = variant[1];
    }
    if (variant[0] === "disabled") {
      disabled = variant[1];
    }
    // if (variant[0] === "label") {
    //   label = variant[1];
    // }
    if (variant[0] === "help text") {
      helpText = variant[1];
    }
    if (variant[0] === "required") {
      required = variant[1];
    }
  }

  if (error === "false") error = null;
  if (disabled === "false") disabled = null;
  if (helpText === "true") helpText = "Help Text";
  else helpText = "";
  if (required === "false") required = null;
  else required = "";

  // let items = [
  //   { id: "item-1", label: "Item 1", value: "item1" },
  //   { id: "item-2", label: "Item 2", value: "item2" },
  //   { id: "item-3", label: "Item 3", value: "item3" },
  // ];
  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-label
        label="Label"
        size={size}
        mode={mode}
        helpertext={helpText}
        disabled={disabled}
        error={error}
        required={required}
        style={{ width: "var(--gds-layout-100" }}
      >
        <gds-single-select
          mode={mode}
          size={size}
          error={error}
          disabled={disabled}
          items={[
            { id: "item-1", label: "Item 1", value: "item1" },
            { id: "item-2", label: "Item 2", value: "item2" },
            { id: "item-3", label: "Item 3", value: "item3" },
          ]}
          defaultselecteditem="item-1"
        ></gds-single-select>
      </gds-label>
    </div>
  );
};
