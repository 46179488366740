import React from "react";
// import "@nielsen-media/gds-checkbox/lib/src";

let mode = ["Mode", ["Light", "Dark"]];
let size = ["Size", ["Regular", "Compact"]];
let helpText = ["Help Text", ["False", "True"]];
let disabled = ["Disabled", ["False", "True"]];
let error = ["Error", ["False", "True"]];
let indeterminate = ["Indeterminate", ["False", "True"]];

export const getCheckboxVariants = function () {
  return [mode, size, helpText, disabled, error, indeterminate];
};

export const getCheckboxComponent = function (variants) {
  let mode = "light";
  let size = "regular";
  let helpText = "false";
  let disabled = null;
  let error = "false";
  let indeterminate = "false";
  let checked = "false";

  for (const variant of variants) {
    if (variant[0] === "mode") {
      mode = variant[1];
    }
    if (variant[0] === "size") {
      size = variant[1];
    }
    if (variant[0] === "help text") {
      helpText = variant[1];
    }
    if (variant[0] === "disabled") {
      disabled = variant[1];
    }
    if (variant[0] === "error") {
      error = variant[1];
    }
    if (variant[0] === "indeterminate") {
      indeterminate = variant[1];
    }
  }

  if (disabled === "false") disabled = null;

  var helpTextString;
  // if (indeterminate === "true") checked = "true";
  if (helpText === "true") helpTextString = "Help text";

  return (
    <div
      id="component-wrapper"
      className={mode === "dark" ? "toggle-dark" : null}
    >
      <gds-checkbox
        mode={mode}
        size={size}
        disabled={disabled}
        label="Label"
        error={error}
        indeterminate={indeterminate}
        checked={checked}
        helpText={helpTextString}
      ></gds-checkbox>
    </div>
  );
};
