import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql, Link } from "gatsby";
import { Divider } from "../components/Divider";
import Layout from "../components/Layout";
import InteractivePlayground from "../components/InteractivePlayground";

function getAnchor(a) {
  if (a === null) {
    return;
  }
  return "#" + a.replace(/\s/g, "-").toLowerCase();
}

function getID(a) {
  return a.replace(/\s/g, "-").toLowerCase();
}

export default function ComponentDetails({ data, location }) {
  const {
    name,
    description,
    linkFigma,
    mafLink,
    webComponentsLink,
    updatedAt,
    usageContent,
    relatedComponents,
    dos,
    donts,
  } = data.contentfulComponent;
  const pageTitle = name;
  const link1title = "View Figma Component";
  const link1url = linkFigma;
  const link2title = "View MAF Storybook";
  const link2url = mafLink;
  const link3title = "View Web Components Storybook";
  const link3url = webComponentsLink;
  const lastUpdated = updatedAt;
  let section1 = "Component Variants";
  let section2 = "Design Principles";
  let section3 = null;
  let section4 = null;

  const componentList = data.allContentfulComponent.nodes;

  // if (contentPrinciples !== null) section3 = "Writing Guidelines";
  if (dos !== null || donts !== null) section4 = "Do's and Don'ts";

  let tocItems = [section1, section2, section3, section4];

  return (
    <Layout location={location}>
      <div className="styled-grid gap-space-800">
        <div className="styled-grid">
          <div className="d-grid gap-space-400">
            <div className="row">
              {pageTitle != null && (
                <div className="col-sm-6 component-title">
                  <div className="gds-size-900-bold">{pageTitle}</div>
                </div>
              )}
              <div className="col-6 gds-size-300-regular text-gray-600 d-flex justify-content-end d-none d-sm-flex">
                Last Updated on {lastUpdated}{" "}
              </div>
            </div>
            {description != null && (
              <div
                className="gds-size-600-regular"
                style={{ maxWidth: "768px" }}
              >
                {description}
              </div>
            )}

            {link1url != null && (
              <div
                className="d-flex flex-wrap gap-space-300"
                style={{ marginLeft: "-12px" }}
              >
                <a target="_blank" rel="noreferrer" href={link1url}>
                  <gds-button
                    color="primary"
                    background="transparent"
                    size="tiny"
                    icon-position="right"
                    icon="export"
                  >
                    {link1title}
                  </gds-button>
                </a>
                {link2url != null && (
                  <a target="_blank" rel="noreferrer" href={link2url}>
                    <gds-button
                      color="primary"
                      background="transparent"
                      size="tiny"
                      icon-position="right"
                      icon="export"
                    >
                      {link2title}
                    </gds-button>
                  </a>
                )}
                {link3url != null && (
                  <a target="_blank" rel="noreferrer" href={link3url}>
                    <gds-button
                      color="primary"
                      background="transparent"
                      size="tiny"
                      icon-position="right"
                      icon="export"
                    >
                      {link3title}
                    </gds-button>
                  </a>
                )}
              </div>
            )}
          </div>

          <Divider />
        </div>
        <div className="row">
          <div className="col-md order-md-1 order-2 gap-space-800 me-space-500 styled-grid">
            {section1 != null && (
              <React.Fragment>
                <div>
                  <a id={getID(section1)} href={getAnchor(section1)}>
                    <h2 className="mb-space-500">{section1}</h2>
                  </a>
                  <InteractivePlayground
                    componentName={pageTitle}
                  ></InteractivePlayground>
                </div>
                <Divider />
              </React.Fragment>
            )}
            {section2 != null && (
              <React.Fragment>
                <div>
                  <a id={getID(section2)} href={getAnchor(section2)}>
                    <h2 className="mb-space-500">{section2}</h2>
                  </a>
                  {usageContent != null ? (
                    <ReactMarkdown className="markdown-content">
                      {usageContent.usageContent}
                    </ReactMarkdown>
                  ) : (
                    <gds-chip color="orange">In Progress</gds-chip>
                  )}
                </div>
                <Divider />
              </React.Fragment>
            )}
            {section3 != null && (
              <React.Fragment>
                <div>
                  <a id={getID(section3)} href={getAnchor(section3)}>
                    <h2 className="mb-space-500">{section3}</h2>
                  </a>
                </div>
                <Divider />
              </React.Fragment>
            )}
            {section4 != null && (
              <React.Fragment>
                <div>
                  <a id={getID(section4)} href={getAnchor(section4)}>
                    <h2 className="mb-space-500">{section4}</h2>
                  </a>
                  <div class="row">
                    {dos.map((doItem) => (
                      <div className="col-6">
                        <div className="do-item">
                          {" "}
                          <div className="icon">
                            <gds-display-icon
                              icon="active-check"
                              foreground="green"
                              foreground-level="300"
                              size="24"
                            ></gds-display-icon>
                          </div>
                          {doItem}
                        </div>
                      </div>
                    ))}
                    {donts.map((dontItem) => (
                      <div className="col-6">
                        <div className="dont-item">
                          <div className="icon">
                            <gds-display-icon-bg
                              mode="light"
                              icon="close"
                              background="red"
                              size="24"
                            ></gds-display-icon-bg>
                          </div>
                          {dontItem}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <Divider />
              </React.Fragment>
            )}
          </div>
          {tocItems != null && (
            <div className="col-md-4 col-lg-3 order-md-2 order-1 mb-space-800 table-of-contents">
              <div className="sticky d-grid gap-space-500">
                <div className="tabs" columns="1">
                  <div
                    className="gds-size-100-bold text-gray-600 mb-space-400"
                    style={{ textTransform: "uppercase" }}
                  >
                    Table of Contents
                  </div>
                  {tocItems.map(
                    (item) =>
                      item !== null && (
                        <a
                          href={getAnchor(item)}
                          className={
                            typeof window !== "undefined" &&
                            window.location.href.includes(
                              getAnchor(item) + "-tab"
                            )
                              ? "gds-size-400-regular tab-item active"
                              : "gds-size-400-regular tab-item"
                          }
                          id={(getAnchor(item) + "-tab").substring(1)}
                        >
                          {item}
                        </a>
                      )
                  )}
                </div>
                {relatedComponents !== null && (
                  <React.Fragment>
                    <Divider />
                    <div className="tabs" columns="1">
                      <div
                        className="gds-size-100-bold text-gray-600 mb-space-400"
                        style={{ textTransform: "uppercase" }}
                      >
                        Related Components
                      </div>
                      {relatedComponents !== null && (
                        <div className="links d-grid gap-space-400 ms-space-300">
                          {relatedComponents
                            .sort()
                            .map((item) =>
                              getComponentItem(item, componentList)
                            )}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

function getComponentItem(item, componentList) {
  for (let i = 0; i < componentList.length; i++) {
    // console.log("Item: " + item + " " + componentList[i].name);
    if (item === componentList[i].name && componentList[i].status === "Ready") {
      // console.log("MATCH");
      return (
        <Link
          to={
            "/components/" +
            componentList[i].name.toLowerCase().replace(/\s/g, "-") +
            "/"
          }
        >
          <gds-link size="400">{componentList[i].name}</gds-link>
        </Link>
      );
    }
  }
  return <div className="gds-size-400-regular ps-space-200">{item}</div>;
}

export const ComponentNames = graphql`
  query ComponentDetails($name: String) {
    contentfulComponent(name: { eq: $name }) {
      id
      linkFigma
      name
      description
      updatedAt(formatString: "MMM DD, YYYY")
      mafLink
      webComponentsLink
      usageContent {
        usageContent
      }
      relatedComponents
      dos
      donts
    }
    allContentfulComponent {
      nodes {
        name
        status
      }
    }
  }
`;
